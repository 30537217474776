import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import CalendarIcon from '@material-ui/icons/CalendarToday';
import DescriptionOutlined from '@material-ui/icons/DescriptionOutlined';
import WavingIcon from '@material-ui/icons/EmojiPeople';
import LanguageIcon from '@material-ui/icons/Language';
import LocalPharmacyIcon from '@material-ui/icons/LocalPharmacy';
import PersonIcon from '@material-ui/icons/Person';
import { inject, observer } from 'mobx-react';
import React from 'react';

import { RouterLinkDiv } from 'src/components/general/routerLink';
import type { RootStore } from 'src/stores/root';
import { claimsQueryParams } from 'src/util/nav';

const EXTERNAL_LINKS = [
  {
    label: 'Help Desk',
    url: 'https://helpdesk.boulder.care',
  },
  {
    label: 'Provider Resources',
    url: 'https://www.notion.so/boulder/Provider-Team-ed1b62a0f91a480189a2f6b89d091b96',
  },
];

const AdminSidebar = ({ rootStore: { auth } }: { rootStore: RootStore }) => (
  <div>
    <List>
      <ListItem button component={RouterLinkDiv} routeName="providers">
        <ListItemIcon>
          <PersonIcon />
        </ListItemIcon>
        <ListItemText primary="Providers" />
      </ListItem>
      <ListItem
        button
        component={RouterLinkDiv}
        routeName="showProvider"
        params={{ id: auth.user?.id || '' }}
      >
        <ListItemIcon>
          <CalendarIcon />
        </ListItemIcon>
        <ListItemText primary="My Calendar" />
      </ListItem>
      <ListItem button component={RouterLinkDiv} routeName="dropInClinicRequests">
        <ListItemIcon>
          <WavingIcon />
        </ListItemIcon>
        <ListItemText primary="Pop-In Queue" />
      </ListItem>
      <ListItem button component={RouterLinkDiv} routeName="inquiries">
        <ListItemIcon>
          <AssignmentIndIcon />
        </ListItemIcon>
        <ListItemText primary="Inquiries" />
      </ListItem>
      <ListItem
        button
        component={RouterLinkDiv}
        routeName="claims"
        queryParams={claimsQueryParams()}
      >
        <ListItemIcon>
          <DescriptionOutlined />
        </ListItemIcon>
        <ListItemText primary="Claims" />
      </ListItem>
      <ListItem button component={RouterLinkDiv} routeName="erx">
        <ListItemIcon>
          <LocalPharmacyIcon />
        </ListItemIcon>
        <ListItemText primary="DoseSpot" />
      </ListItem>
      <Divider />
      {EXTERNAL_LINKS.map(link => (
        <ListItem key={link.url} button onClick={() => window.open(link.url, '_blank')}>
          <ListItemIcon>
            <LanguageIcon />
          </ListItemIcon>
          <ListItemText primary={link.label} />
        </ListItem>
      ))}
    </List>
  </div>
);

export default inject('rootStore')(observer(AdminSidebar));
